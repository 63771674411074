import React from "react";
import { LoadingContext } from "./LoadingContext";

export const Loading = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="position-relative h-100">
          <div className="position-absolute top-50 d-flex start-50 translate-middle justify-content-center align-items-center d-block h-100 w-100">
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const LoadingProvider: React.FC = (props) => {
  const [isLoading, setIsLoading] = React.useState(false);

  const show = () => {
    setIsLoading(true);
  };

  const hide = () => {
    setIsLoading(false);
  };

  const contextData = {
    isLoading,
    show,
    hide,
  };

  return (
    <LoadingContext.Provider value={contextData}>
      {props.children}
      {isLoading && <Loading />}
    </LoadingContext.Provider>
  );
};
