import { Link } from "react-router-dom";
import classnames from "classnames";

export const Breadcrumb = ({ items }) => {
  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        <li className="breadcrumb-item">
          <Link to="/">Home</Link>
        </li>
        {items?.map((e) => (
          <li
            className={classnames("breadcrumb-item", {
              active: e?.active,
            })}
            aria-current="page"
          >
            <Link to={e?.url}>{e?.name}</Link>
          </li>
        ))}
      </ol>
    </nav>
  );
};
