import { useRef, FormEvent } from "react";
import { useHistory } from "react-router-dom";

export const SearchBar = () => {
  const history = useHistory();
  const searchRef = useRef(null);
  const onSubmitSearch = (e: FormEvent) => {
    e.preventDefault();
    const search: any = searchRef.current;
    history.push(`/search?q=${search.value}`);
  };

  return (
    <>
      <h1 className="h2 mb-30 text-white text-center">
        Chúng tôi có thể giúp gì cho bạn?
      </h1>
      <form onSubmit={onSubmitSearch} className="dx-form dx-form-group-inputs">
        <input
          ref={searchRef}
          type="text"
          className="form-control"
          placeholder="Tìm kiếm thông tin thắc mắc..."
        />
        <button className="dx-btn dx-btn-lg" type="submit">
          Tìm kiêm
        </button>
      </form>
    </>
  );
};
