import { MobileSearchBar } from 'components/search-bar';
import React from 'react';

type Props = {
  searchBar?: boolean;
};

export const MobileLayout: React.FC<Props> = ({ searchBar, children }) => {
  return (
    <>
      {searchBar && <MobileSearchBar />}
      <div className="container-fluid">{children}</div>
    </>
  );
};

export default MobileLayout;
