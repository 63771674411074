import { gql } from '@apollo/client';

export const GetCategories = gql`
  query GetCategories {
    categories(where: { parent: 7 }) {
      nodes {
        name
        slug
      }
    }
  }
`;

export const GetInvestmentCategories = gql`
  query GetCategories {
    categories(where: { parent: 16 }) {
      nodes {
        name
        slug
      }
    }
  }
`;
