import { FormEvent, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export const MobileSearchBar = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const history = useHistory();
  const searchRef = useRef(null);
  const onSubmitSearch = (e: FormEvent) => {
    e.preventDefault();
    const search: any = searchRef.current;
    history.push(`/mobile/search?q=${search.value}`);
  };

  return (
    <div className="container-fluid bg-primary py-3">
      <form onSubmit={onSubmitSearch}>
        <input
          ref={searchRef}
          className="form-control border-0 text-white p-10 searchbar"
          placeholder="Tìm kiếm thông tin thắc mắc..."
          style={{ backgroundColor: 'transparent' }}
          defaultValue={query.get('q') ?? ''}
        />
      </form>
    </div>
  );
};
