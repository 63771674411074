import { useQuery } from '@apollo/client';
import MobileLayout from 'components/common/layout/MobileLayout';
import Loading from 'components/common/loading';
import { GetPostsByCategory } from 'queries';
import { useEffect, useState } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';

type Props = {};

type ComposedProps = Props &
  RouteComponentProps<{
    slug: string;
  }>;

export const MobilePostList: React.FC<ComposedProps> = (props) => {
  const { slug } = props?.match?.params;
  const { data, loading, fetchMore } = useQuery<any>(GetPostsByCategory, {
    variables: { slug, first: 15 },
  });
  const name = data?.category?.name;
  const [posts, setPosts] = useState<any>([]);

  const loadMore = () => {
    console.log('aaaa', data?.category?.posts?.pageInfo?.hasNextPage);
    if (data?.category?.posts?.pageInfo?.hasNextPage) {
      fetchMore({
        variables: {
          slug,
          after: data?.category?.posts?.pageInfo?.endCursor,
          first: 15,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          if (fetchMoreResult?.category?.posts?.edges?.length) {
            setPosts([...posts, ...fetchMoreResult?.category?.posts?.edges]);
          }
        },
      });
    }
  };

  useEffect(() => {
    setPosts(data?.category?.posts?.edges);
  }, [data]);

  useEffect(() => {
    document.body.onscroll = () => {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        loadMore();
      }
    };
  }, [data, posts]);

  return (
    <MobileLayout searchBar>
      <div className="mt-30">
        <h6 className="text-uppercase mb-10">{name}</h6>
        {posts?.map((e: any) => (
          <div key={e?.node?.slug}>
            <hr className="my-0" />
            <Link
              to={`/mobile/chi-tiet/${e?.node?.slug}`}
              className="d-block py-3"
            >
              {e?.node?.title}
            </Link>
          </div>
        ))}
        {posts?.length === 0 && <span>Không có bài viết nào</span>}
        {loading && <Loading />}
      </div>
    </MobileLayout>
  );
};

export default MobilePostList;
