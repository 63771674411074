import React from "react";
import Loading from "../loading";
import { Vote } from "../../vote";

type Props = {
  loading?: boolean;
  children?: React.ReactNode;
};

export const Content: React.FC<Props> = (props) => {
  if (props.loading) {
    return <Loading />;
  }
  return (
    <React.Fragment>
      <div className="row">
        <div className="col-lg-12">
          <div className="dx-box dx-box-decorated">
            <div className="dx-blog-post">
              <div className="dx-blog-post-box">{props.children}</div>
            </div>
          </div>
          {/* <Vote /> */}
        </div>
      </div>
    </React.Fragment>
  );
};

Content.defaultProps = {
  loading: true,
};
