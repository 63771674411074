import { useQuery } from '@apollo/client';
import MobileLayout from 'components/common/layout/MobileLayout';
import Loading from 'components/common/loading';
import { SearchPosts } from 'queries';
import { Link, useLocation } from 'react-router-dom';

export const MobileSearch = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const { data, loading } = useQuery(SearchPosts, {
    variables: { keyword: query.get('q') },
  });
  const posts = data?.posts?.nodes;

  return (
    <MobileLayout searchBar>
      <div className="mt-30">
        <h6 className="text-uppercase mb-10">Tìm kiếm</h6>
        {loading && <Loading />}
        {posts?.map((e: any) => (
          <div key={e?.slug}>
            <hr />
            <Link to={`/mobile/chi-tiet/${e?.slug}`}>{e?.title}</Link>
          </div>
        ))}
        {posts?.length === 0 && <span>Không có bài viết nào</span>}
      </div>
    </MobileLayout>
  );
};

export default MobileSearch;
