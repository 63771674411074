import React from "react";

export interface LoadingContextData {
  isLoading: boolean;
  show: () => void;
  hide: () => void;
}

export const loadingContextDefaultValue: LoadingContextData = {
  isLoading: false,
  show: () => null,
  hide: () => null,
};

export const LoadingContext = React.createContext<LoadingContextData>(
  loadingContextDefaultValue
);
