import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import { SearchBar } from '../../components/search-bar';
import { SearchPosts } from '../../queries/posts';

export const Search = (props: any) => {
  const query = new URLSearchParams(props.location.search);
  const { data } = useQuery(SearchPosts, {
    variables: { keyword: query.get('q') },
  });
  const posts = data?.posts?.nodes;

  return (
    <>
      <header className="dx-header dx-box-1">
        <div className="container">
          <div className="bg-image bg-image-parallax">
            <img
              src="assets/images/bg-header-4.png"
              className="jarallax-img"
              alt=""
            />
            <div style={{ backgroundColor: 'rgba(27, 27, 27, .2)' }}></div>
          </div>

          <div className="row justify-content-center">
            <div className="col-xl-7">
              <SearchBar />
            </div>
          </div>
        </div>
      </header>

      <div className="container py-md-4">
        {posts?.map((e: any) => (
          <div key={e?.slug} className="mb-10">
            <Link to={`/chi-tiet/${e?.slug}`}>
              <div className="d-flex">
                <div className="flex-grow-1">{e?.title}</div>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </>
  );
};

export default Search;
