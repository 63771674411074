import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Breadcrumb } from '../../components/breadcrumb';
import { SearchBar } from '../../components/search-bar';
import { GetCategories, GetPostsByCategory } from '../../queries';

type Props = {};

type ComposedProps = Props &
  RouteComponentProps<{
    slug: string;
  }>;

export const PostList: React.FC<ComposedProps> = (props) => {
  const { slug } = props?.match?.params;
  const { data } = useQuery(GetPostsByCategory, {
    variables: { slug },
  });
  const name = data?.category?.name;
  const posts = data?.category?.posts?.edges;

  const { data: categoryData } = useQuery(GetCategories);
  const categories = categoryData?.categories?.nodes;

  const [breadcrumb, setBreadcrumb] = useState<object[]>([]);

  useEffect(() => {
    setBreadcrumb([{ url: `/danh-muc/${slug}`, name }]);
  }, [posts, categories]);

  return (
    <div className="dx-main">
      <header className="dx-header dx-box-1">
        <div className="container">
          <div className="bg-image bg-image-parallax">
            <img
              src="/assets/images/bg-header-4.png"
              className="jarallax-img"
              alt=""
            />
            <div style={{ backgroundColor: 'rgba(27, 27, 27, .2)' }}></div>
          </div>

          <div className="row justify-content-center">
            <div className="col-xl-7">
              <SearchBar />
            </div>
          </div>
        </div>
      </header>

      <div className="container py-md-4">
        <Breadcrumb items={breadcrumb} />
        <div className="row">
          <div className="col-md-3">
            <ul>
              {categories?.map((e: any) => (
                <li key={e?.slug}>
                  <Link to={`/danh-muc/${e?.slug}`}>{e?.name}</Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="col-md-9">
            {posts?.map((e: any) => (
              <div key={e?.node?.slug} className="mb-10">
                <Link to={`/chi-tiet/${e?.node?.slug}`}>{e?.node?.title}</Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostList;
