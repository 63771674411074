import { ApolloProvider } from '@apollo/client';
import MobileAffiliate from 'pages/affilitate/MobileAffiliatePost';
import React, { Suspense } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import client from './apollo-client';
import { LoadingProvider } from './context/LoadingContext/Loading';
import { MobilePostList, MobileSearch, Post, PostList, Search } from './pages';

const DesktopLayout = React.lazy(
  () => import('./components/common/layout/DesktopLayout')
);

const HomePage = React.lazy(() => import('./pages/home/HomePage'));
const MobileHomePage = React.lazy(() => import('./pages/home/MobileHomePage'));

const mobileRoutes = [
  {
    path: '/mobile/home',
    exact: true,
    component: MobileHomePage,
  },
  {
    path: '/mobile/search',
    exact: true,
    component: MobileSearch,
  },
  {
    path: '/mobile/danh-muc/:slug',
    exact: true,
    component: MobilePostList,
  },
  {
    path: '/mobile/chi-tiet/:slug',
    exact: false,
    component: Post,
  },
  {
    path: '/mobile/cong-tac-vien/:slug',
    exact: false,
    component: Post,
  },
];

const desktopRoutes = [
  {
    path: '/',
    exact: true,
    component: HomePage,
  },
  {
    path: '/search',
    exact: true,
    component: Search,
  },
  {
    path: '/danh-muc/:slug',
    exact: true,
    component: PostList,
  },
  {
    path: '/chi-tiet/:slug',
    exact: false,
    component: Post,
  },
];

function App() {
  return (
    <Suspense fallback={<div />}>
      <LoadingProvider>
        <ApolloProvider client={client}>
          <BrowserRouter>
            <Switch>
              <Route path="/mobile/:path?">
                <Switch>
                  {mobileRoutes.map((props) => (
                    <Route key={props.path} {...props} />
                  ))}
                </Switch>
              </Route>
              <Route>
                <DesktopLayout>
                  <Switch>
                    {desktopRoutes.map((props) => (
                      <Route key={props.path} {...props} />
                    ))}
                  </Switch>
                </DesktopLayout>
              </Route>
            </Switch>
          </BrowserRouter>
        </ApolloProvider>
      </LoadingProvider>
    </Suspense>
  );
}

export default App;
