import { gql } from '@apollo/client';

export const GetPostBySlug = gql`
  query GetPostBySlug($slug: ID!) {
    post(id: $slug, idType: SLUG) {
      id
      title
      slug
      date
      content
    }
  }
`;

export const SearchPosts = gql`
  query SearchPosts($keyword: String!) {
    posts(where: { search: $keyword, categoryId: 7 }) {
      nodes {
        slug
        title
      }
    }
  }
`;

export const GetPostsByCategory = gql`
  query GetPostsByCategory($slug: ID!, $after: String = "", $first: Int = 100) {
    category(id: $slug, idType: SLUG) {
      name
      posts(after: $after, first: $first) {
        edges {
          cursor
          node {
            slug
            title
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
`;

export const GetPostBaseOnCategory = gql`
  query GetPostBaseOnCategory($slug: String!, $category: ID!) {
    category(id: $category, idType: SLUG) {
      id
      posts(where: { name: $slug }) {
        edges {
          node {
            id
            content
            title
          }
        }
      }
    }
  }
`;
