import { useQuery } from '@apollo/client';
import { Content } from 'components/common/content';
import { GetPostBySlug } from 'queries';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';


type Props = {};

type ComposedProps = Props &
  RouteComponentProps<{
    slug: string;
  }>;

export const Post: React.FC<ComposedProps> = (props) => {
  const { slug } = props?.match?.params;
  const { loading, data } = useQuery(GetPostBySlug, {
    variables: {
      slug,
    },
  });
  return (
    <div className="container">
      <Content loading={loading}>
        <h3>{data?.post?.title}</h3>
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: data?.post?.content }}
        />
      </Content>
    </div>
  );
};

export default Post;
